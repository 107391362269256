// src/App.js
import React from "react";
import CardForm from "./components/CardForm";
import "./App.css"; // Import the CSS file
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./routes/HomePage";
import { Provider } from "react-redux";
import store from "./redux/store";
import NewCustomer from "./routes/NewCustomer";
import Login from "./routes/Login";
import ProtectedRoute from "./routes/ProtectedRoute";
import Customer from "./routes/Customer";
import Interest from "./routes/Interest";
import Questionnaire from "./routes/Questionnaire";
import GDPR from "./routes/GDPR";
import ModalError from "./components/ModalError";
import ModalPreview from "./components/ModalPreview";
import { Worker } from "@react-pdf-viewer/core";

function App() {
  return (
    <Provider store={store}>
      <div className="centered-container">
        <Router>
          <CardForm>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/new"
                element={
                  <ProtectedRoute>
                    <NewCustomer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/:trdr"
                element={
                  <ProtectedRoute>
                    <Customer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/:trdr/interest"
                element={
                  <ProtectedRoute>
                    <Interest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/:trdr/questionnaire/:questionnaire"
                element={
                  <ProtectedRoute>
                    <Questionnaire />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/:trdr/gdpr"
                element={
                  <ProtectedRoute>
                    <GDPR />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<div>Page not Found</div>} />
            </Routes>
          </CardForm>
        </Router>
        <ModalError />
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <ModalPreview />
        </Worker>
      </div>
    </Provider>
  );
}

export default App;
