import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPreview } from "../redux/slices/ui";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ModalPreview = () => {
  const dispatch = useDispatch();
  const preview = useSelector((state) => state.ui.preview);
  const sn = useSelector((state) => state.login?.sn);

  const pdfUrl = preview
    ? `${process.env.REACT_APP_API_ENDPOINT}/proxy?url=${encodeURIComponent(
        `https://${sn}.oncloud.gr/s1services?filename=${preview.url}`
      )}`
    : null;

  return (
    <Modal
      show={preview?.show}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
      onHide={() => dispatch(setPreview(false))}
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-0 overflow-hidden">
        {pdfUrl ? (
          <div style={{ height: "100%", width: "100%" }}>
            <Viewer fileUrl={pdfUrl} />
          </div>
        ) : (
          <p className="text-center">No preview available</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreview;
