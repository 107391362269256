import React from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/slices/ui";
import getFromJS from "../services/getFromJS";
import { useNavigate, useParams } from "react-router-dom";
import { FaFileWaveform } from "react-icons/fa6";
import { MdEmail, MdPrivacyTip } from "react-icons/md";
import { GiLaserWarning } from "react-icons/gi";
import { setTrdr } from "../redux/slices/trdr";

const Customer = () => {
  const dispatch = useDispatch();
  const trdrState = useSelector((state) => state.trdr);

  const navigate = useNavigate();

  const formRef = React.useRef(null);
  const { trdr } = useParams();

  const [edit, setEdit] = React.useState(false);

  const [init, setInit] = React.useState({
    firstName: trdrState?.firstName ?? "",
    lastName: trdrState?.lastName ?? "",
    birthday: trdrState?.birthday ?? "",
    email: trdrState?.email ?? "",
    phone: trdrState?.phone ?? "",
    address: trdrState?.address ?? "",
    zip: trdrState?.zip ?? "",
    city: trdrState?.city ?? "",
    sex: trdrState?.sex ?? "",
    gdpr: trdrState?.gdpr ?? [],
  });

  const [firstName, setFirstName] = React.useState(init.firstName);
  const [lastName, setLastName] = React.useState(init.lastName);
  const [birthday, setBirthday] = React.useState(init.birthday);
  const [email, setEmail] = React.useState(init.email);
  const [phone, setPhone] = React.useState(init.phone);
  const [address, setAddress] = React.useState(init.address);
  const [zip, setZip] = React.useState(init.zip);
  const [city, setCity] = React.useState(init.city);
  const [sex, setSex] = React.useState(init.sex);
  const [validated, setValidated] = React.useState(false);

  const fetchTrdr = async () => {
    dispatch(setLoading(true));
    const response = await getFromJS("getTrdr", { trdr });
    if (response.success) {
      dispatch(setTrdr(response));
      setInit({
        firstName: response?.firstName ?? "",
        lastName: response?.lastName ?? "",
        birthday: response?.birthday ?? "",
        email: response?.email ?? "",
        phone: response?.phone ?? "",
        address: response?.address ?? "",
        zip: response?.zip ?? "",
        city: response?.city ?? "",
        sex: response?.sex ?? "",
        gdpr: response?.gdpr ?? [],
      });

      setFirstName(response?.firstName ?? "");
      setLastName(response?.lastName ?? "");
      setEmail(response?.email ?? "");
      setPhone(response?.phone ?? "");
      setAddress(response?.address ?? "");
      setZip(response?.zip ?? "");
      setCity(response?.city ?? "");
      setBirthday(response?.birthday ?? "");
      setSex(response?.sex);
    } else {
      navigate("/404");
    }
    dispatch(setLoading(false));
  };

  React.useEffect(() => {
    if (trdrState) {
      setInit({
        firstName: trdrState?.firstName ?? "",
        lastName: trdrState?.lastName ?? "",
        birthday: trdrState?.birthday ?? "",
        email: trdrState?.email ?? "",
        phone: trdrState?.phone ?? "",
        address: trdrState?.address ?? "",
        zip: trdrState?.zip ?? "",
        city: trdrState?.city ?? "",
        sex: trdrState?.sex ?? "",
        gdpr: trdrState?.gdpr ?? "",
      });
      setFirstName(trdrState?.firstName ?? "");
      setLastName(trdrState?.lastName ?? "");
      setEmail(trdrState?.email ?? "");
      setPhone(trdrState?.phone ?? "");
      setAddress(trdrState?.address ?? "");
      setZip(trdrState?.zip ?? "");
      setCity(trdrState?.city ?? "");
      setBirthday(trdrState?.birthday ?? "");
      setSex(trdrState?.sex);
      return;
    } else {
      fetchTrdr();
    }
  }, []);

  const fields = [
    {
      controlId: "firstName",
      value: firstName,
      placeholder: "Όνομα",
      type: "text",
      required: true,
      onChange: (e) => setFirstName(e.target.value),
    },
    {
      controlId: "lastName",
      value: lastName,
      placeholder: "Επώνυμο",
      type: "text",
      required: true,
      onChange: (e) => setLastName(e.target.value),
    },
    {
      controlId: "birthday",
      value: birthday,
      placeholder: "Ημ/νία Γέννησης",
      type: "date",
      required: true,
      onChange: (e) => setBirthday(e.target.value),
    },
    {
      controlId: "phone",
      value: phone,
      placeholder: "Τηλ.",
      type: "tel",
      required: true,
      pattern: "^\\+\\d{1,3}\\d{10,14}$|^\\d{10}$",
      onChange: (e) => setPhone(e.target.value),
    },
    {
      controlId: "address",
      value: address,
      placeholder: "Διεύθυνση",
      type: "text",
      required: false,
      onChange: (e) => setAddress(e.target.value),
    },
    {
      controlId: "city",
      value: city,
      placeholder: "Πόλη",
      type: "text",
      required: true,
      onChange: (e) => setCity(e.target.value),
    },
    {
      controlId: "zip",
      value: zip,
      placeholder: "Τ.Κ.",
      type: "tel",
      required: false,
      pattern: "^\\d{5}(-\\d{4})?$",
      onChange: (e) => setZip(e.target.value),
    },
    {
      controlId: "email",
      value: email,
      placeholder: "email",
      type: "email",
      required: true,
      onChange: (e) => setEmail(e.target.value),
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = formRef.current;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(setLoading(true));

      const data = {
        trdr,
        firstName,
        lastName,
        birthday,
        email,
        phone,
        address,
        zip,
        city,
        sex,
      };

      const response = await getFromJS("setTrdr", data);
      dispatch(setLoading(false));
      setEdit(false);
    }
    setValidated(true);
  };

  const handleCancel = () => {
    setEdit(false);
    setFirstName(init.firstName);
    setLastName(init.lastName);
    setEmail(init.email);
    setPhone(init.phone);
    setAddress(init.address);
    setZip(init.zip);
    setCity(init.city);
    setBirthday(init.birthday);
    setSex(init.sex);
  };

  const handleExternalSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <>
      <Card.Body className="overflow-auto">
        <Form
          className="customer-form"
          ref={formRef}
          autoComplete="off"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          {fields.map((item, i) => (
            <FloatingLabel
              key={i}
              controlId={item.controlId}
              label={
                <>
                  {item.placeholder} {item.required && item.value === "" && <span style={{ color: "red" }}>*</span>}
                </>
              }
              className="form-item mb-2"
            >
              <Form.Control
                {...item}
                controlId={undefined}
                autoComplete="off"
                isInvalid={item.required && validated && !item.value}
                isValid={item.required && validated && !item.value}
                readOnly={!edit}
                disabled={!edit}
              />
            </FloatingLabel>
          ))}
          <FloatingLabel
            controlId="sex"
            label={<>Φύλο {sex === "" && <span style={{ color: "red" }}>*</span>}</>}
            className="form-item mb-2"
          >
            <Form.Select
              placeholder="sex"
              value={sex}
              required={true}
              onChange={(e) => setSex(e.target.value)}
              // Only apply validation feedback to required fields
              isInvalid={validated && !sex}
              isValid={validated && !sex}
              readOnly={!edit}
              disabled={!edit}
            >
              <option value="">Επιλέξτε Φύλο</option>
              <option value="1">Αρρεν</option>
              <option value="2">Θήλυ</option>
            </Form.Select>
          </FloatingLabel>
        </Form>
        <div className="d-flex flex-row justify-content-between my-4" style={{ borderBottom: "1px solid black" }}>
          <FaFileWaveform size={40} onClick={() => navigate(`/customer/${trdr}/interest`)} />
          <MdEmail size={50} />
          <MdPrivacyTip size={45} onClick={() => navigate(`/customer/${trdr}/gdpr`, { state: init })} />
          <GiLaserWarning size={50} onClick={() => navigate(`/customer/${trdr}/questionnaire/${1000}`)} />
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        {!edit ? (
          <>
            <Button onClick={() => navigate("/")} variant="seconodary">
              Πίσω
            </Button>
            <Button
              onClick={() => {
                setEdit(true);
                setValidated(false);
              }}
            >
              Επεξεργασία
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleCancel} variant="secoendary">
              Ακύρωση
            </Button>
            <Button onClick={handleExternalSubmit} type="submit" variant="success">
              Αποθήκευση
            </Button>
          </>
        )}
      </Card.Footer>
    </>
  );
};

export default Customer;
